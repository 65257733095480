import { cx } from 'classix';
import { Entry } from 'contentful';
import Image, { ImageProps } from 'next/image';
import { Highlight } from 'repos/highlights';

type Props = {
  highlights: Entry<Highlight>[];
  imageOverride?: Partial<ImageProps>;
};

export default function HighlightsBlock({ highlights, imageOverride }: Props) {
  return (
    <section>
      <div className="mx-auto my-24 flex max-w-7xl flex-col gap-20 space-y-12">
        {highlights.map((highlight, idx) => (
          <div
            key={highlight.sys.id}
            className={cx(
              'flex flex-col items-center gap-10 px-6 sm:flex-row',
              idx % 2 === 1 && 'sm:flex-row-reverse'
            )}
          >
            <div className="flex-[2]">
              <h2 className="text-r-sm mb-4  uppercase tracking-wider">
                {highlight.fields.subtitle}
              </h2>
              <h3 className="text-r-4xl mb-4 font-bold">
                {highlight.fields.title}
              </h3>
              <p className="text-r-lg">{highlight.fields.description}</p>
            </div>
            <div className="flex-1 select-none justify-center">
              <Image
                src={`https:${highlight.fields.image.fields.file.url}?fm=webp`}
                alt={highlight.fields.title}
                width={450}
                height={352}
                {...imageOverride}
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
