import { Entry } from 'contentful';
import Marquee from 'react-fast-marquee';
import { Customer } from 'repos/customers';

type Props = {
  customers: Entry<Customer>[];
  title: string;
};

export default function CustomersBlock(props: Props) {
  const { customers, title } = props;

  return (
    <section className="py-24">
      <div className="mx-auto mb-16 max-w-7xl px-6">
        <h2 className="text-r-4xl text-center font-bold">{title}</h2>
      </div>
      <Marquee speed={30} direction="left" gradient={false}>
        {customers.map((customer) => (
          /* eslint-disable-next-line @next/next/no-img-element */
          <img
            key={customer.sys.id}
            src={`https:${customer.fields.image.fields.file.url}?fm=webp&h=36`}
            alt={customer.fields.name}
            className="mr-12 inline h-[36px] select-none grayscale md:mr-16"
          />
        ))}
      </Marquee>
    </section>
  );
}
